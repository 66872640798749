<template>
  <div class="classify professional-select-tab">
    <div class="item">
      <div class="left">
        <p>专业分类：</p>
      </div>
      <div class="right">
        <ul class="classifyParent">
          <li :class="is_active(item)"
              v-for="item in commentClassify"
              :key="item.key"
              @click="changeClassify(1,item)">{{item.title}}</li>
        </ul>
        <ul v-if="showChildClassify"
            class="classifyChild">
          <li v-for="item in childClassify"
              :key="item.key"
              :class="classify_child_id == item.id?'child_active':''"
              @click="changeClassify(2,item)">{{item.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classify_id: {
      default: String
    },
    all: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      childClassify: [],
      showChildClassify: false,
      commentClassify: [],
      classify_child_id: ''
    };
  },
  async created () {
    await this.getCommentClassify();//获取专业分类
  },
  methods: {
    changeClassify (flag, item) {
      if (flag == 1) {
        this.$emit('changeClassify', flag, item);
      } else {
        this.$emit('changeClassify', flag, item);
        this.classify_child_id = item.id;
      }
    },
    is_active (item) {
      if (item.id == this.classify_id) {
        if (this.childClassify) {
          return 'active activeMark';
        } else {
          return 'active';
        }
      }
    },
    getCommentClassify () {
      this.$http.get("/api/app/comment/classify").then(data => {
        if (this.all) {
          data.unshift({ title: '全部', id: 0 })
        }
        this.commentClassify = data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.classify {
  border: none;
  padding-left: 0;
  margin: 0;
  padding-bottom: 0;
  .classifyChild {
    background: rgba(59, 144, 255, 0.1);
    border-radius: 5px;
    padding-left: 26px;
    li {
      color: #333333;
      height: 42px !important;
      line-height: 42px !important;
      margin-bottom: 0 !important;
      font-size: 14px;
    }
    .child_active {
      color: $primary_color;
    }
  }
  .classifyParent {
    border: none;
    padding-left: 0;
    margin: 0;
    padding-bottom: 0;
  }
  .classifyParent li {
    position: relative;
  }
  .classifyParent li.activeMark {
    &::before {
      content: "";
      position: absolute;
      background: transparent;
      z-index: 0;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(59, 144, 255, 0.1);
    }
  }
  .classifyParent li.active:nth-child(1) {
    &::before {
      border-bottom: 10px solid #ffffff;
    }
  }
}
</style>
