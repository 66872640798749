<template>
  <div class="main-container">
    <div class="question-nav">
      <router-link :to="{ name: 'Home' }">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">名师</span>
    </div>
    <div class="select-tab margin_bot_20">
      <Classify
        ref="classify"
        :classify_id="classify_id"
        :all="true"
        @changeClassify="changeClassify"
      />
      <div class="item" v-for="(attrItem, index) in attrs" :key="index">
        <div class="left">
          <p>{{ attrItem.attr }}：</p>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(valueItem, index) in attrItem.value"
              :key="index"
              :class="{ active: attrCondition[attrItem.id] == valueItem.id }"
              @click="onClickAttr(attrItem.id, valueItem.id)"
            >
              {{ valueItem.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <TeacherItem
        v-for="(item, index) in teachers"
        :teacher="item"
        :key="index"
      />
      <div
        class="empty-card"
        v-for="item in cols - (teachers.length % cols)"
        :key="teachers.length + item"
      ></div>
    </div>
    <el-pagination
      background
      @current-change="onCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next, total, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import Classify from "../components/Classify.vue";
import TeacherItem from "@/modules/stu/views/TeacherItem.vue";

export default {
  name: "teacher",
  components: { Classify, TeacherItem },
  data() {
    return {
      attrs: [],
      attrCondition: {},
      teachers: [],
      classify_id: 0,
      cols: 4, // 列数
      page: 1,
      limit: 12,
      total: 0,
    };
  },
  created() {
    this.requestAttr();
    this.requestData();
  },
  methods: {
    // on event
    onClickAttr(attrID, valueID) {
      this.$set(this.attrCondition, attrID, valueID);
      this.page = 1;
      this.requestData();
    },
    onCurrentChange(val) {
      this.page = val;
      this.requestData();
    },
    onClickTeacher(item) {
      this.$router.push({
        name: "ShowTeachersDetaial",
        params: {
          id: item.id,
        },
        query: {
          route_name: "ShowTeachers",
          route_label: "名师",
        },
      });
    },
    async changeClassify(flag, item) {
      if (flag == 1) {
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else {
        this.subClassify = item.id;
      }
      this.page = 1;
      this.requestData();
    },
    // other
    // request
    requestAttr() {
      this.$http.get("/api/app/attribute/3").then((data) => {
        data.forEach((i) => {
          i.value &&
            i.value.unshift({
              id: 0,
              name: "全部",
            });
          this.attrCondition[i.id] = 0;
        });
        this.attrs = data;
      });
    },
    requestData() {
      const ary = [];
      for (const key in this.attrCondition) {
        if (this.attrCondition[key]) {
          ary.push(this.attrCondition[key]);
        }
      }
      let params = {
        classify_id: this.subClassify || this.classify_id,
        page: this.page,
        limit: this.limit,
        attr_val_id: ary.join(),
      };
      this.$http.get("/api/app/teacher/search/attrs", params).then((data) => {
        this.total = data.total;
        this.teachers = data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-tab {
  .item .left {
    width: 90px;
  }
}
.content {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .teacher {
    margin-bottom: 20px;
  }

  .card {
    width: 282px;
    height: 323px;
    margin-bottom: 20px;
    cursor: pointer;
    /deep/ .el-card__body {
      height: 100%;
      box-sizing: border-box;
    }
    .card-content {
      text-align: center;
      position: relative;
      height: 100%;
      .header img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
      }
      .name {
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-top: 17px;
      }
      .level {
        font-size: 14px;
        color: #999;
        margin-top: 9px;
      }
      .introduction {
        position: absolute;
        bottom: 0;
        height: 88px;
        font-size: 14px;
        color: #666;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
  .empty-card {
    width: 282px;
  }
}
.question-nav {
  height: 62px;
  line-height: 62px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  a {
    color: #333333;
  }
}

.slash {
  padding: 0 8px;
}

.margin_bot_20 {
  margin: 0 0 20px;
}
.margin_right_40 {
  margin-right: 40px;
}
</style>
