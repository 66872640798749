<template>
  <div class="news-show main-container">
    <div class="message-box">
      <h1 class="center">关于我们</h1>
      <div id="content" v-html="info"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      info: ""
    };
  },
  async created() {
    let info = await this.$http.get("/api/app/get/config/about_us");
    let div = document.createElement("div");
    div.innerHTML = info.about_us;
    div.querySelectorAll("img").forEach(el => {
      el.removeAttribute("title");
    });
    this.info = div.innerHTML;
  },
  mounted() {
    window.$(window).scrollTop(0);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.news-show {
  margin-top: 20px;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.message-box {
  position: relative;
  min-height: 600px;
  margin: 0 auto;
  padding: 30px 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 236, 243, 1);
  border-radius: 5px;
}
</style>
<style lang="scss">
.message-box img {
  max-width: 100%;
}
</style>