<template>
  <div class="news-show main-container">
    <div class="message-box">
      <h1 class="center">帮助中心</h1>
      <div v-html="info"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpCenter",
  data() {
    return {
      info: ""
    };
  },
  mounted() {
    window.$(window).scrollTop(0);
  },
  async created() {
    let data = await this.$http.get("/api/app/get/help");
    let div = document.createElement("div");
    div.innerHTML = data.help_center;
    div.querySelectorAll("img").forEach(el => {
      el.removeAttribute("title");
    });
    this.info = div.innerHTML;
  }
};
</script>

<style scoped>
.news-show {
  margin-top: 20px;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.message-box {
  position: relative;
  min-height: 600px;
  margin: 0 auto;
  padding: 30px 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 236, 243, 1);
  border-radius: 5px;
}

.message-box img {
  width: 100%;
}
</style>
<style lang="scss">
.message-box img {
  max-width: 100%;
}
</style>