<template>
  <div class="teacher" @click="onShowTeacher(teacher)">
    <div class="angle">
      <img src="@/assets/images/teacher_block_angle.png" alt="" />
    </div>

    <div class="profile">
      <div v-if="teacher.classify_name" class="classify">
        {{ teacher.classify_name }}
      </div>

      <img class="avatar" :src="teacher.avatar" />
    </div>

    <div class="icon">
      <img src="@/assets/images/teacher_block_icon.png" alt="" />
    </div>

    <div class="intro">
      <div class="name">
        {{ teacher.name }}
      </div>

      <div class="introduction">{{ teacher.introduction }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacher: {
      default: {
        id: 0,
        name: "",
        real_name: "",
        classify_name: "",
        avatar: "",
      },
    },
  },
  created() {
    this.setName();
  },
  beforeUpdate() {
    this.setName();
  },
  methods: {
    setName() {
      const real_name = this.teacher.real_name;
      this.teacher.name = real_name;
      if (real_name && real_name.includes("-")) {
        const names = real_name.split("-");
        this.teacher.classify_name = names[0];
        this.teacher.name = names[1];
      }
    },
    onShowTeacher(item) {
      this.$router.push({
        name: "ShowTeachersDetaial",
        params: {
          id: item.id,
        },
        query: {
          route_name: "ShowTeachers",
          route_label: "名师",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-col {
  display: flex;
  justify-content: center;
}

.teacher {
  cursor: pointer;
  width: 320px;
  height: 460px;
  background: #f4f4f4;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.16);
  position: relative;

  .profile {
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .classify {
      position: absolute;
      left: 20px;
      top: 20px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3a3a3a;
      line-height: 25px;
    }

    .avatar {
      width: 112px;
      height: 112px;
      border-radius: 50%;
    }
  }

  .angle {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 200px;
    width: 60px;
    height: 60px;
  }

  .intro {
    background: white;
    height: 210px;
    padding-left: 20px;
    padding-top: 20px;

    .name {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3a3a3a;
      line-height: 33px;
    }

    .introduction {
      margin-top: 12px;
      max-width: 275px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9a9a9a;
      line-height: 28px;
      max-height: 120px;
      overflow: auto;
    }
  }
}
</style>
